import React, { Component } from 'react';
import { RichText } from '../../richTextOptions';
import ContentfulBlockArticles from '../ContentfulBlockArticles';
import NomineesPastWacky from './NomineesWacky';
import { WackyCard } from './WackyCard';

class WackyCategory extends Component {
     constructor(props) {
          super(props);
     }

     render() {
          const { category } = this.props;
          var allStories = category.wacky_pet___story;
          const bannerWackyCategory = category?.banners?.file.url;
         const backgroundImage = 'url("' + category?.banners?.file.url + '")';
          return (
               <>
                    <section className="wacky-category-infomation" style={{ backgroundImage: `url(${bannerWackyCategory})`, '--section-background-image': backgroundImage }}>
                         <div className="container">
                              <div className="content">
                                   <h1>{category.h1 ? category.h1 : category.name}</h1>
                                   {category.top50Description && <RichText data={category.top50Description}></RichText>}
                              </div>
                         </div>
                    </section>
                    <section className="wacky-best-top">
                         <div className="container">
                              <WackyCard allStories={allStories} />
                         </div>
                    </section>
                    <section className="wacky-past-year">
                         <NomineesPastWacky />
                    </section>
               </>
          );
     }
}

export default WackyCategory;
